/* Author:

*/


// Global Variables

var isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);



// Miscellaneous JS

(function($) {
	$(function() {
		FastClick.attach(document.body);
	});
		
})(jQuery);